
import { BApp } from 'buetify/lib/components';
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import BuetifyDocumentationNavbar from '../../components/buetifyNavbar/BuetifyDocumentationNavbar.vue';
import BuetifyMenu from './menu/BuetifyMenu';
import { DocumentationHeader } from './header';

export default defineComponent({
	name: 'documentation',
	components: {
		BApp,
		BuetifyMenu,
		BuetifyDocumentationNavbar,
		DocumentationHeader,
		BHorizontalDivider
	},
	setup() {
		return {
			route: useRouter().currentRoute
		};
	}
});
